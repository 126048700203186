#root {
    --content-wrapper-width: 1400px;
}

.SearchField {
    --search-bar-max-width-desktop: 220px;
}

.BannerBlock-Container {
    --banner-block-column-2-gap: 3rem;
    --banner-block-column-5-gap: 1rem;
    --banner-block-item-height-col-2: 45rem;
    --banner-block-item-height-col-4: 35rem;
    --banner-block-item-height-col-5: 28rem;
    --banner-block-item-height-col-6: var(--banner-block-item-height-col-5);

    @include mobile {
        --banner-block-item-height-col-5: var(--banner-block-item-height-col-2);
        --banner-block-item-height-col-6: var(--banner-block-item-height-col-2);
      }
    
      @include tablet {
        --banner-block-item-height-col-3: 45rem;
        --banner-block-item-height-col-4: 50rem;
        --banner-block-item-height-col-5: var(--banner-block-item-height-col-3);
        --banner-block-item-height-col-6: var(--banner-block-item-height-col-3);
      }
    
      @include narrow-desktop {
        --banner-block-item-height-col-6: var(--banner-block-item-height-col-4);
        --banner-block-item-height-col-5: var(--banner-block-item-height-col-3);
      }
    
      @include before-wide-desktop {
        --banner-block-item-height-col-6: var(--banner-block-item-height-col-4);
      }
}

.BannerItem {   
    --banner-item-content-padding: 0 1rem 1rem 1rem;
    --banner-item-link-border: none;
}

.ProductListWidget {
    --product-list-widget-h2-font-size: 2.5rem;
    --product-list-widget-h2-font-style: normal;
    --product-list-widget-h2-font-weight: normal;

    h2 {
        line-height: 1.8;
    }

    @include tablet {
        --product-list-widget-h2-font-size: 2rem;
    }
}

.Footer {
    --footer-copyright-background-color: var(--secondary-dark-color);
    --footer-newsletter-button-background-color: #000;
}