@import 'variables';

.HeaderMenu {
    &-Aside {
        display: none;
    }
}

.HomePageInfo {
    max-width: var(--content-wrapper-width);
    margin: 0 auto 2rem auto;
    padding: 0 1.5rem;

    @include desktop {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 3rem;
    }

    &-Column {
        margin-bottom: 2rem;
        
        @include desktop {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 2.5rem;
            margin: 0;
        }

        &_one {
            @include desktop {
                grid-template-columns: 1fr;
            }
        }
    }

    &-Block {
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);

        @include mobile {
            &:first-of-type {
                margin-bottom: 2rem;
            }
        }

        > .block-static-block {
            height: 100%;
            display: flex;
            flex-direction: column;
        }

        .HomePageInfo-Column_one & {
            padding: 3rem;

            h1 {
                text-transform: uppercase;
                font-style: italic;
                margin-bottom: 1.5rem;

                strong {
                    color: var(--secondary-base-color);
                }
            }
            
            p {
                font-size: 1.5rem;
                line-height: 3rem;
                font-weight: 300;
            }

            .block-static-block {
                justify-content: center;
                align-items: center;
            }
        }

        &Title {
            padding: 2rem;
            background-color: var(--secondary-light-color);

            h2 {
                margin: 0;
                text-transform: uppercase;
                font-size: 1.6rem;
            }
        }

        &Content {
            padding: 2rem;
            display: flex;
            flex-direction: column;
            height: 100%;
            font-size: 1.6rem;

            img {
                width: 100%;
                height: auto;
            }

            li {
                @include reset-list-item;
                line-height: 3rem;
                display: flex;
                justify-content: flex-start;
                font-size: inherit;

                span {
                    font-weight: bold;
                    width: 160px;

                    + span {
                        font-weight: normal;
                    }
                }
            }

            .customer-service-wrapper {
                align-self: center;
                justify-self: center;
            }

            .phone {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-top: 1.6rem;
                margin-top: 1.8rem;
                border-top: 1px solid var(--secondary-light-color);
                font-size: 1.6rem;

                i {
                    margin-right: 1rem;
                    font-size: 3rem;
                    color: var(--secondary-base-color);
                }
            }
        }
    }
}

.BannerBlock {
    &-Item {
      &_type_column_5 {
        height: 27rem;
      }
    }
  }

.BannerItem {
    &-Title {
        .CmsPage & {
            font-size: 4rem;
        }

        .BannerBlock-Item_type_column_5.BannerItem_isBlock & {
            font-size: 1.5rem;
            text-transform: uppercase;
        }
    }

    &-ContentBlock {
        .BannerBlock-Item_type_column_5.BannerItem_isBlock & {
            width: 100%;
            text-align: center;
        }
    }
}

.StoreLocatorSelect {
    display: none;
}
